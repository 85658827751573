<app-header></app-header>

<section class="container mt-5 pt-0 pt-lg-5 mb-5">
  <div class="et_pb_text_inner">
    <h5 class="text-center">
      Effective October 1, 2024, our service charge prices will be changing.
      Please note, all services initiated prior to this date will maintain the
      pricing that was in effect at the time of the request.
    </h5>
    <br />
    <h1 class="services-heading mb-0">Service Charges</h1>
    <hr />
    <hr />
    <hr />
    <table>
      <thead>
        <tr>
          <th scope="col">Service Charge</th>
          <th scope="col">Description</th>
          <th class="empty-row" scope="col" style="width: 49px">&nbsp;</th>
          <th scope="col" class="pricing">
            <strong>&nbsp;Pricing&nbsp;</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td data-label="Service Charge">
            Home Filing Service Charge (With Escrow)
          </td>
          <td data-label="Description">
            <span dir="ltr"
              >Charge for processing a customer’s request for a home sale or
              refinance with payment being made through Escrow</span
            >
          </td>
          <td class="empty-row" data-label="&nbsp;" style="width: 10px">
            &nbsp;
          </td>
          <td data-label="Pricing">&nbsp;$150</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td style="min-width: 10px">
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">
            Home Filing Service Charge (Without Escrow)
          </td>
          <td>
            Charge for processing a customer’s request for a home sale or home
            refinance.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$350</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">
            System Buyout Service Charge (Without Filing)
          </td>
          <td data-label="Description">
            Charge for processing a customer’s request for a system buyout
            associated with their solar system without filing.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$200</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">
            System Buyout Service Charge (With Filing)
          </td>
          <td data-label="Description">
            Charge for processing a customer’s request for a system buyout
            associated with their solar system with filing.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$300</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">
            PUC Notice Filing Service Charge (CA only)
          </td>
          <td data-label="Description">
            Service charge for processing a customer’s request to file a CA PUC
            Notice. This service charge does not apply if a Home Filing Service
            Charge or Rush Service Charge applies to the same request.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$175</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">Rush Service Charge (5-7 days)</td>
          <td data-label="Description">
            Expedited rush fee for processing a home filing request.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$750</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">Rush Service Charge (2-4 days)</td>
          <td data-label="Description">
            Expedited rush fee for processing a home filing request.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$1750</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">Rush Service Charge (Next day)</td>
          <td data-label="Description">
            Expedited rush fee for processing a home filing request.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$2950</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">Title Report Pull Service Charge</td>
          <td data-label="Description">
            Processing fee for securing title for the customer solar system.
            Processing time is 10 business days.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$1500</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">Credit Application Service Charge</td>
          <td data-label="Description">
            Credit application fee for buyer purchasing solar system from
            current owner.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$39</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">FNMA Addendum Service Charge</td>
          <td data-label="Description">
            Fee charged in response to customer requests to create a lender
            required addendum to the customer’s solar agreement that asserts
            lender’s rights in the event of a foreclosure.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$75</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">
            Customer Requested Truck-roll Service Charge
          </td>
          <td data-label="Description">
            Charge for a customer authorized truck roll after Spruce has already
            confirmed the system is properly operating and the customer still
            desires a truck roll.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$375</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">Missed Appointment Service Charge</td>
          <td data-label="Description">
            Charge in the event a scheduled Service Call is executed and the
            homeowner is not available/home when the technician arrives, and a
            repeat service call is needed.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$375</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">
            3rd Party TSR Contractor Service Charge
          </td>
          <td data-label="Description">
            Charge for approval processing where the customer elects to use
            their own private contractor for Temporary System Removal.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$250</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">
            Roof Leak Inspection Service Charge
          </td>
          <td data-label="Description">
            Charge when customer requests dispatch of a technician for a roof
            leak and the findings are that it is not solar related.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$400</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">
            Monitoring Interruption Service Charge
          </td>
          <td data-label="Description">
            Charge for dispatch of a technician for a reported monitoring issue
            caused by customer action/inaction or other problems caused by
            customer’s network connection.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$375</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">Reconnection Service Charge</td>
          <td data-label="Description">
            Charge for dispatch of a technician to reconnect solar system after
            disconnect due to non-payment.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$375</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">
            Unaddressed Shading/Soiling Service Charge
          </td>
          <td data-label="Description">
            Charge for mitigation of shading/soiling (e.g. tree trimming,
            excessive bird droppings, excessive dust/dirt) and is not done as
            per customer’s contract and materially impacts solar system
            production (>10%).
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$375</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">
            Unresponsive Contact Service Charge
          </td>
          <td data-label="Description">
            Charge to a customer after customer is unresponsive to three (3)
            attempts to contact the customer via email, phone, or written
            notice.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$25</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">Excess Debris Service Charge</td>
          <td data-label="Description">
            Charge to a customer for removal of debris, including animal
            infestation, which is impacting solar system production and is not
            addressed as per customer contract.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">Greater of $1,500 or actual cost</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">Late Fee</td>
          <td data-label="Description">
            Charge for failing to pay the monthly payment on/before the due
            date. Applicable charge may be less in certain states.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$25</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">
            Asset Recovery & Processing Service Charge
          </td>
          <td data-label="Description">
            Charge to a customer for collection processing for outstanding
            balances >120 days.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$125</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">
            Asset Recovery Legal Service Charge
          </td>
          <td data-label="Description">
            Charge to a customer for commencing legal review for outstanding
            balances >180 days.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">
            $200 plus 5% of outstanding balance at payoff
          </td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">Collecting payment over the phone</td>
          <td data-label="Description">
            Charge for collecting payment over the phone.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$4.75</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
        <tr>
          <td data-label="Service Charge">Paper Statement Fee</td>
          <td data-label="Description">
            Effective Nov. 1st, 2023 there will be a nominal monthly charge of
            $1 to receive paper statements.
          </td>
          <td class="empty-row">&nbsp;</td>
          <td data-label="Pricing">&nbsp;$1</td>
        </tr>
        <tr class="empty-row">
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

<app-footer></app-footer>
